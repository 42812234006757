.countdown{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    z-index: 3;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    pointer-events: none;
}
.container{
    position: absolute;
    bottom: 5%;
    width: inherit;
    height: auto;
    padding: 10px;
}
.countdownnumbers{
    color:white;
    font-size: 36px;
    font-weight: 400;
}
.names {
    color: white;
    margin-bottom: 2rem;
}